import React, {useState} from 'react';
import './App.css';
import {MainTimeZoneBox} from "./components/MainTimeZoneBox";
import {Typography} from "@mui/material";
import moment, {Moment} from "moment-timezone";
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";

function App() {
  const [dateArray, setDateArray] = useState<Moment[]>(
    [
      moment.tz('Asia/Seoul'),
      moment.tz('America/New_York'),
      moment.tz('US/Central'),
      moment.tz('Europe/London'),
    ]);
  const onRemove = (index: number) => {
    const newArray = [...dateArray];
    newArray.splice(index, 1);
    setDateArray(newArray);
  }

  const globalDateChanged = (date: Moment, exceptKey: number) => {
    const newArray = [...dateArray];
    for (let i = 0; i < newArray.length; i++) {
      if (i !== exceptKey) {
        newArray[i] = date.clone().tz(dateArray[i].tz() || 'Europe/Athens');
      }
    }
    setDateArray(newArray);
  }

  return (
    <div className="App">
      <header className="App-header">
        <Typography variant="h1">Timezone Converter</Typography>
        {dateArray.map((date, index) =>
          <MainTimeZoneBox
            propagatedDatetime={date} key={index} index={index} onRemove={onRemove}
            globalDateUpdater={globalDateChanged}
          />)
        }
        <IconButton onClick={() => setDateArray([...dateArray, moment()])}>
          <AddIcon style={{color: 'blue'}}/>
        </IconButton>
      </header>
    </div>
  );
}

export default App;
