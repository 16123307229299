import {Button, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import moment, {Moment} from "moment-timezone";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';

type MainTimeZoneBoxProps = {
  propagatedDatetime: Moment
  onRemove: (index: number) => void
  index: number
  globalDateUpdater: (date: Moment, exceptKey: number) => void
}

const rootStyles = {
  // padding: '1rem',
  // margin: '1rem',
};

const itemStyles = {
  padding: '1rem',
  margin: '1rem',
  textAlign: 'center',
  backgroundColor: '#efefef',
};

export const MainTimeZoneBox = ({propagatedDatetime, onRemove, index, globalDateUpdater}: MainTimeZoneBoxProps) => {
  const [userTypedDatetime, setUserTypedDatetime] = useState<string>(propagatedDatetime.format('YYYY-MM-DD HH:mm:ss'));
  const [parsedDatetime, setParsedDatetime] = useState<Moment>(propagatedDatetime);
  const [updatingState, setUpdatingState] = useState<String>('');
  const [userTypedTimezone, setUserTypedTimezone] = useState<string>(propagatedDatetime.tz() || moment.tz.guess());
  const [parsedTimezone, setParsedTimezone] = useState<string>(propagatedDatetime.tz() || moment.tz.guess());

  useEffect(() => {
    // console log propagated datetime
    setParsedDatetime(propagatedDatetime.clone().tz(parsedTimezone));
  }, [propagatedDatetime, parsedTimezone])

  useEffect(() => {
    // log parsed datetime and Time zone

    console.log(parsedDatetime.format(), parsedDatetime.tz());
  }, [parsedDatetime, parsedTimezone])

  useEffect(() => {
    // parse userTypedDatetime to date
    const guessedDatetime = moment.tz(userTypedDatetime, parsedTimezone);
    if (!guessedDatetime.isValid()) {
      setUpdatingState("Failed to parse date");
      return;
    }
    // if guessed Date time is valid
    setUpdatingState('updated');
    setParsedDatetime(guessedDatetime);
  }, [userTypedDatetime])

  useEffect(() => {
    // userTypedTimezone is updated then, update parsedTimezone and propagatedDatetime
    const guessedTimezone = moment.tz.zone(userTypedTimezone);
    if (!guessedTimezone) {
      setUpdatingState("Failed to parse timezone");
      return;
    }
    setUpdatingState('Timezone updated');
    setParsedTimezone(userTypedTimezone);
  }, [userTypedTimezone])

  const applyTextDate = (date: Moment | undefined) => {
    if (date === undefined) {
      return;
    }
    if (date) {
      // make timezone
      globalDateUpdater(date, index);
      setUpdatingState('Date updated');
    } else {
      setUpdatingState("Failed to parse date");
    }
  }

  return <>
    {index !== 0 && <Divider variant="inset"/>}
    <Grid container style={rootStyles}>
      <Grid item md={2}>
        <Grid container direction="column">
          <Grid item>
            <Autocomplete
              value={userTypedTimezone}
              options={moment.tz.names()}
              onChange={(e, value) => {
                if (!value) {
                  return
                }
                setUserTypedTimezone(value);
                if (moment.tz.zone(value)) {
                  setParsedTimezone(value)
                }
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Timezone"/>}
            ></Autocomplete>
          </Grid>
          <Grid item>
            <Typography>{parsedTimezone}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4}>
        <Grid container direction="column">
          <Grid item> <TextField variant="standard" value={userTypedDatetime} onChange={(e) => {
            setUserTypedDatetime(e.target.value)
          }
          }
          ></TextField></Grid>
          <Grid item>
            <Typography>{parsedDatetime.format("YYYY-MM-DD HH:mm:ss")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item direction="row" md={5}>
        <Grid container>
          <Grid item md={6}>
            <Button onClick={() => applyTextDate(parsedDatetime)}>Synchronize time</Button></Grid>
          <Grid item md={6}>
            <Typography variant="h6">{updatingState}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item direction="row" md={1}>
        <IconButton onClick={() => onRemove(index)} aria-label="delete">
          <DeleteIcon/>
        </IconButton>
      </Grid>
    </Grid>
  </>

}

MainTimeZoneBox.defaultProps = {
  name: 'World',
}